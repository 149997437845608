@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-spacing: 0; }

body {
  line-height: 1; }

hr {
  display: block;
  width: 100%; }

.efekt, .btn, .anasayfa-slider .slick-dots li button, .slider-btn, .slider-btn:after, .as-arrows a {
  transition: all 0.25s ease-in-out; }

.flex, .header__ust, .header__buttons, .header__alt, .header--flex, .footer-alt, .footer-alt__sag, .sayfa-wrapper, .proje-bilgi, .proje-detay-paging, .iletisim-icon {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.bdrs, .iletisim-card, .iletisim-harita {
  border-radius: 0.25rem; }

.dikeyAbsolute, .slider-bg, .proje-harita__detaylar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.dikeyFlex {
  display: flex;
  align-items: center; }

.bxs, .btn:Hover, .btn--turuncu:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out; }
  .bxs:Hover, .btn:Hover, .btn--turuncu:Hover:hover {
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1), 0 8px 18px rgba(0, 0, 0, 0.1); }

.bxsh {
  transition: all 0.2s ease-in-out; }
  .bxsh:Hover {
    box-shadow: 0 10px 22px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1); }

.fz9 {
  font-size: 0.9rem; }

.fz85 {
  font-size: 0.85rem; }

.fz8 {
  font-size: 0.8rem; }

.fz7 {
  font-size: 0.7rem; }

.bgcover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.fw300 {
  font-weight: 300; }

.fw400 {
  font-weight: 400; }

.fw500 {
  font-weight: 500; }

.fw600 {
  font-weight: 600; }

.fw700 {
  font-weight: 700; }

.fw800 {
  font-weight: 800; }

.mb15 {
  margin-bottom: 15px; }

.mb30 {
  margin-bottom: 30px; }

.mb50 {
  margin-bottom: 50px; }

.mb60 {
  margin-bottom: 60px; }

.mt15 {
  margin-top: 15px; }

.mb75 {
  margin-bottom: 75px; }

.mb100 {
  margin-bottom: 100px; }

.mt30 {
  margin-top: 30px; }

.mt50 {
  margin-top: 50px; }

.mt60 {
  margin-top: 60px; }

.mt75 {
  margin-top: 75px; }

.mt100 {
  margin-top: 100px; }

.my-5 {
  margin-top: 50px;
  margin-bottom: 50px; }

.visible-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none; }

.visible-scrollbar::-webkit-scrollbar:vertical {
  width: 2px; }

.visible-scrollbar::-webkit-scrollbar:horizontal {
  height: 2px; }

.visible-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1); }

.visible-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1); }

.scrollbar::-webkit-scrollbar {
  width: 4px; }

.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; }

.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 6px; }

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #BEE9E8; }

.scrollbar-yatay::-webkit-scrollbar {
  height: 4px; }

.scrollbar-yatay::-webkit-scrollbar-track {
  background: #f1f1f1; }

.scrollbar-yatay::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 6px; }

.scrollbar-yatay::-webkit-scrollbar-thumb:hover {
  background: #BEE9E8; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*:after,
*:before {
  box-sizing: inherit; }

body {
  font-family: "Montserrat", sans-serif;
  line-height: 1.5;
  background-attachment: fixed;
  color: #1B4965; }

img {
  max-width: 100%; }

button,
input,
select,
textarea {
  font-family: "Montserrat", sans-serif; }

.pos-rel {
  position: relative; }

.slick-slide {
  outline: transparent !important; }

.noscroll {
  overflow: hidden; }

.tab-panel {
  display: none; }

.tab-active {
  display: block;
  animation: fadeIn 0.5s; }

.tabcontent {
  animation: fadeIn 0.5s; }

.fadeIn {
  animation: fadeIn 0.5s; }

.btn {
  background: #3E4651;
  color: #fff;
  padding: 8px 20px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500; }
  .btn:Hover {
    background: #BEE9E8; }
  .btn--turuncu {
    background: none;
    border: 2px solid #BEE9E8;
    color: #BEE9E8; }
    .btn--turuncu:hover {
      color: #fff; }
  .btn--outline {
    background: none;
    border: 1px solid #1B4965;
    color: #1B4965; }
    .btn--outline:hover {
      background: #5FA8D3;
      color: #fff;
      border-color: #5FA8D3; }
  .btn--icon {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .btn--icon svg {
      margin-left: 6px; }
      .btn--icon svg path {
        fill: #fff; }
    .btn--icon:Hover {
      background: #5FA8D3 !important; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.baslik {
  font-size: 2.2rem;
  font-weight: 700;
  display: block; }
  .baslik--kucuk {
    font-size: 1.6rem; }
  .baslik--buyuk {
    font-size: 2.8rem; }

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
::after,
::before {
  box-sizing: inherit; }

.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1440px; } }

.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

.row {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  -ms-flex-wrap: wrap; }

.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  min-height: 1px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  -ms-flex-positive: 1;
  -ms-flex-preferred-size: 0; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
  -ms-flex: 0 0 auto; }

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
  -ms-flex: 0 0 8.333333%; }

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  -ms-flex: 0 0 16.666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
  -ms-flex: 0 0 25%; }

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  -ms-flex: 0 0 33.333333%; }

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  -ms-flex: 0 0 41.666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  -ms-flex: 0 0 50%; }

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  -ms-flex: 0 0 58.333333%; }

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  -ms-flex: 0 0 66.666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
  -ms-flex: 0 0 75%; }

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
  -ms-flex: 0 0 83.333333%; }

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
  -ms-flex: 0 0 91.666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  -ms-flex: 0 0 100%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex: 0 0 auto; }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%; }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
    -ms-flex: 0 0 25%; }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%; }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%; }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%; }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
    -ms-flex: 0 0 75%; }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%; }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex: 0 0 auto; }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%; }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
    -ms-flex: 0 0 25%; }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%; }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%; }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%; }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
    -ms-flex: 0 0 75%; }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%; }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex: 0 0 auto; }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%; }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
    -ms-flex: 0 0 25%; }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%; }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%; }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%; }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
    -ms-flex: 0 0 75%; }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%; }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex: 0 0 auto; }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%; }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
    -ms-flex: 0 0 25%; }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%; }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%; }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%; }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
    -ms-flex: 0 0 75%; }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%; }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%; } }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.flex-wrap {
  flex-wrap: wrap; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.justify-content-start {
  justify-content: flex-start !important;
  -ms-flex-pack: start !important; }

.justify-content-end {
  justify-content: flex-end !important;
  -ms-flex-pack: end !important; }

.justify-content-center {
  justify-content: center !important;
  -ms-flex-pack: center !important; }

.justify-content-between {
  justify-content: space-between !important;
  -ms-flex-pack: justify !important; }

.justify-content-around {
  justify-content: space-around !important;
  -ms-flex-pack: distribute !important; }

.align-items-start {
  align-items: flex-start !important;
  -ms-flex-align: start !important; }

.align-items-end {
  align-items: flex-end !important;
  -ms-flex-align: end !important; }

.align-items-center {
  align-items: center !important;
  -ms-flex-align: center !important; }

.m-0 {
  margin: 0 !important; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.fa,
.fab,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-long-arrow-alt-down:before {
  content: "\f309"; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-recycle:before {
  content: "\f1b8"; }

.fa-map-marked-alt:before {
  content: "\f5a0"; }

.fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.fa-trash-alt:before {
  content: "\f2ed"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.fa-times:before {
  content: "\f00d"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-heart:before {
  content: "\f004"; }

.fa-calendar:before {
  content: "\f133"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-file-alt:before {
  content: "\f15c"; }

.fa-map-marker-alt:before {
  content: "\f3c5"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-phone:before {
  content: "\f095"; }

.fa-map:before {
  content: "\f279"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-search:before {
  content: "\f002"; }

.fa-user:before {
  content: "\f007"; }

.fa-bars:before {
  content: "\f0c9"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-th:before {
  content: "\f00a"; }

.fa-ellipsis-h:before {
  content: "\f141"; }

.fa-ellipsis-v:before {
  content: "\f142"; }

.fa-grip-horizontal:before {
  content: "\f58d"; }

.fa-comment:before {
  content: "\f075"; }

.fa-edit:before {
  content: "\f044"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-address-card:before {
  content: "\f2bb"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-user-plus:before {
  content: "\f234"; }

.fa-minus:before {
  content: "\f068"; }

.fa-plus:before {
  content: "\f067"; }

.fa-clock:before {
  content: "\f017"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-caret-up:before {
  content: "\f0d8"; }

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../css/webfonts/fa-brands-400.eot");
  src: url("../css/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../css/webfonts/fa-brands-400.woff2") format("woff2"), url("../css/webfonts/fa-brands-400.woff") format("woff"), url("../css/webfonts/fa-brands-400.ttf") format("truetype"), url("../css/webfonts/fa-brands-400.svg#fontawesome") format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands'; }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../css/webfonts/fa-regular-400.eot");
  src: url("../css/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../css/webfonts/fa-regular-400.woff2") format("woff2"), url("../css/webfonts/fa-regular-400.woff") format("woff"), url("../css/webfonts/fa-regular-400.ttf") format("truetype"), url("../css/webfonts/fa-regular-400.svg#fontawesome") format("svg"); }

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../css/webfonts/fa-solid-900.eot");
  src: url("../css/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../css/webfonts/fa-solid-900.woff2") format("woff2"), url("../css/webfonts/fa-solid-900.woff") format("woff"), url("../css/webfonts/fa-solid-900.ttf") format("truetype"), url("../css/webfonts/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff; }

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff; }

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d; }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  cursor: pointer;
  padding-top: 3px; }

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px; }

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem; }

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none; } }

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem); }

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5); }

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none; }

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef; }

.custom-select::-ms-expand {
  opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef; }

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse"; }

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.custom-range:focus {
  outline: none; }

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range::-moz-focus-outer {
  border: 0; }

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none; } }

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none; } }

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none; } }

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem; }

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem; }

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem; }

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd; }

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default; }

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd; }

.custom-range:disabled::-moz-range-track {
  cursor: default; }

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none; } }

.btn-mobil,
.mobil {
  display: none; }

.btn-mobil {
  transform: scale(0.7); }

.arama-bg,
.mobil-bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 996; }

.arka-active {
  display: block; }

.main-slider {
  position: relative;
  margin-top: -150px; }

.anasayfa-slider {
  position: relative;
  background: #fcfcfc; }
  .anasayfa-slider .item {
    position: relative; }
    .anasayfa-slider .item img {
      max-width: 100%; }
  .anasayfa-slider .slick-dots {
    bottom: 0;
    left: 40px;
    text-align: left;
    width: auto;
    z-index: 3; }
  .anasayfa-slider .slick-dots li {
    margin: 0;
    width: auto; }
  .anasayfa-slider .slick-dots li button {
    background: rgba(255, 255, 255, 0.2);
    padding: 0;
    width: 40px;
    height: 4px; }
    .anasayfa-slider .slick-dots li button:before {
      content: ''; }
  .anasayfa-slider .slick-active button {
    background: #fff !important; }
  .anasayfa-slider .btn {
    background: #BEE9E8;
    color: #1B4965;
    width: 250px; }
    .anasayfa-slider .btn path {
      fill: #1B4965; }
    .anasayfa-slider .btn:hover {
      color: #fff; }
      .anasayfa-slider .btn:hover path {
        fill: #fff; }

.slider-detay {
  position: absolute;
  top: 50%;
  left: 60px;
  width: calc(100% - 120px);
  transform: translateY(-50%);
  z-index: 2; }

.slider-baslik {
  display: block;
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 60px;
  line-height: 1.2;
  width: 50%; }

.slider-yazi {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 6px;
  line-height: 1.2; }

.slider-btn {
  display: inline-block;
  position: relative;
  font-size: 1.2rem;
  font-weight: 600;
  color: #1B4965;
  border-bottom: 2px solid #1B4965;
  text-decoration: none; }
  .slider-btn:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    background: #5FA8D3;
    margin: 0 auto;
    width: 0;
    height: 2px; }
  .slider-btn:hover:after {
    width: 100%; }

.as-arrows {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 60px;
  right: 0;
  font-size: 20px;
  color: #fff;
  width: 80px;
  z-index: 3; }
  .as-arrows a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    background: #fff;
    color: #3E4651;
    cursor: pointer;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    text-decoration: none; }
    .as-arrows a:hover {
      background: #5FA8D3;
      color: #fff;
      text-shadow: 1px 1px 22px #000; }

.anasayfa-nav {
  position: absolute;
  bottom: 8px;
  left: 15px; }
  .anasayfa-nav span {
    display: inline-block;
    position: relative;
    font-size: 11px;
    font-weight: 600;
    color: #fff;
    width: 15px; }

.slider-alt {
  position: absolute;
  bottom: 0;
  left: 60px;
  width: calc(100% - 120px); }

.slider-bar {
  position: absolute;
  bottom: 25px;
  width: 100%; }

.slider-progress {
  position: relative;
  bottom: 6px;
  background: rgba(255, 255, 255, 0.6);
  overflow: hidden;
  width: 100%;
  height: 3px; }

.slider-progress .progress {
  position: relative;
  background: #fff;
  width: 0;
  height: 3px; }

.pagingInfo {
  position: absolute;
  bottom: 15px;
  font-size: 2rem;
  font-weight: 700;
  color: #fff; }

.harita {
  outline: transparent !important;
  height: 800px; }
  .harita #map,
  .harita canvas,
  .harita iframe {
    outline: transparent !important; }

.header {
  position: relative;
  height: 150px;
  z-index: 2; }
  .header__sag {
    border-left: 1px solid #fff;
    width: calc(100% - 290px); }
  .header__ust {
    font-size: 0.9rem;
    font-weight: 500;
    padding-left: 30px;
    border-bottom: 1px solid #fff;
    height: 60px; }
    .header__ust a {
      color: #fff;
      text-decoration: none;
      transition: color 0.25s ease-in-out; }
      .header__ust a:hover {
        color: #1B4965; }
  .header__alt {
    padding-left: 30px;
    height: 90px; }
  .header--flex {
    border-bottom: 1px solid #fff; }
  .header--ic .header--flex,
  .header--ic .header__sag,
  .header--ic .header__ust {
    border-color: #E0E0E0; }
  .header--ic .header__ust a {
    color: #1B4965; }
    .header--ic .header__ust a:hover {
      color: #5FA8D3; }
  .header--ic .menu__link {
    color: #1B4965; }
    .header--ic .menu__link:hover {
      color: #5FA8D3; }

.logo {
  width: 290px; }

.sosyal {
  margin-right: 30px; }
  .sosyal__link {
    font-size: 1.1rem;
    margin-left: 15px; }

.btn-arama {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #fff; }

.menu__link {
  display: inline-block;
  color: #fff;
  margin-right: 30px;
  line-height: 90px;
  text-decoration: none;
  transition: color 0.25s ease-in-out;
  font-weight: 600; }
  .menu__link:hover {
    color: #1B4965; }

.header-btn {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background: #BEE9E8;
  color: #1B4965;
  padding: 10px 15px;
  border-radius: 32px; }
  .header-btn:hover {
    background: #fff; }

.harita-baslik {
  position: absolute;
  top: 10%;
  left: 5%;
  color: #1B4965;
  z-index: 3; }
  .harita-baslik h1 {
    display: block;
    font-size: 4rem;
    font-weight: 700; }
  .harita-baslik strong {
    font-size: 2rem;
    font-weight: 700; }

.main-hakkimizda {
  padding: 60px 0; }
  .main-hakkimizda .container {
    background: #f4f4f4;
    padding: 15px; }
  .main-hakkimizda .row {
    align-items: center; }
  .main-hakkimizda__baslik {
    display: block;
    font-family: "Roboto Slab", serif;
    font-size: 4rem;
    margin-bottom: 30px;
    line-height: 1.2; }
  .main-hakkimizda__yazi {
    font-size: 1.1rem;
    font-weight: 500;
    padding-left: 30px; }

.main-projeler {
  position: relative;
  background: #1B4965;
  padding: 120px 0; }
  .main-projeler .row {
    align-items: center; }
  .main-projeler .btn--icon {
    background: #1B4965;
    color: #fff;
    width: 220px; }
  .main-projeler .projeler-slider {
    margin-bottom: 0 !important;
    z-index: 2; }
  .main-projeler .baslik {
    font-family: "Roboto Slab", serif;
    font-size: 3rem; }
  .main-projeler .yazi {
    font-weight: 500;
    line-height: 1.8; }

.slider-bg {
  right: 0;
  background: #BEE9E8;
  width: 70%;
  height: calc(100% - 120px);
  z-index: 1; }

.slider-sag {
  position: relative;
  color: #1B4965;
  padding-left: 60px !important;
  padding-right: 20% !important; }
  .slider-sag .slider-ic {
    position: relative;
    z-index: 2; }

.footer {
  padding-top: 30px;
  padding-bottom: 10px; }
  .footer__col3 {
    padding-left: 30px; }

.footer-menu__link {
  display: inline-block;
  font-weight: 600;
  color: #1B4965;
  text-decoration: none;
  transition: color 0.25s ease-in-out;
  margin-bottom: 10px; }
  .footer-menu__link:hover {
    color: #5FA8D3; }

.footer-menu--buyuk {
  font-size: 1.8rem; }

.footer-adres strong {
  display: block;
  font-weight: 700;
  margin-bottom: 10px; }

.footer-adres span {
  font-size: 0.9rem;
  font-weight: 500; }

.footer-iletisim {
  font-size: 2rem;
  font-weight: 600; }
  .footer-iletisim a {
    font-size: 1.5rem;
    color: #1B4965;
    text-decoration: none;
    transition: color 0.25s ease-in-out; }
    .footer-iletisim a:hover {
      color: #5FA8D3; }

.footer-alt {
  font-size: 0.9rem;
  font-weight: 500; }
  .footer-alt__sag a {
    display: inline-block;
    color: #1B4965;
    text-decoration: none; }
  .footer-alt__sag .tools__link {
    padding-right: 15px;
    margin: 15px;
    border-right: 1px solid #1B4965; }

.proje-slider-nav {
  position: relative;
  z-index: 3; }

.proje-slider-arrows {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.proje-arrow {
  opacity: 0.7;
  transition: opacity 0.25s ease-in-out; }
  .proje-arrow:hover {
    opacity: 1; }
  .proje-arrow svg {
    vertical-align: middle; }

.prevAS2 {
  margin-right: 15px; }

.breadcrumb {
  font-size: 0.9rem;
  font-weight: 500;
  color: #777; }
  .breadcrumb__item {
    display: inline-block; }
  .breadcrumb__link {
    color: #777;
    text-decoration: none; }
    .breadcrumb__link:hover {
      text-decoration: underline; }
  .breadcrumb__ayrac {
    display: inline-block;
    margin: 0 5px; }

.proje {
  transition: background 0.25s ease-in-out;
  padding: 15px; }
  .proje:hover {
    background: #1B4965; }
    .proje:hover .proje__baslik,
    .proje:hover .proje__kategori {
      color: #fff; }
  .proje a {
    text-decoration: none; }
  .proje__resim {
    display: block;
    margin-bottom: 15px;
    width: 100%; }
  .proje__baslik {
    display: block;
    font-size: 2rem;
    font-weight: 600;
    color: #1B4965;
    margin-bottom: 4px;
    transition: color 0.25s ease-in-out; }
  .proje__kategori {
    font-size: 0.9rem;
    color: #828282;
    transition: color 0.25s ease-in-out; }

.sayfa-wrapper {
  align-items: flex-start; }

.sol-menu {
  width: 300px; }

.sayfa-sag {
  width: calc(100% - 360px); }

hr {
  background: #E0E0E0;
  border: none;
  height: 1px; }

.filtre__baslik {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  color: #5FA8D3;
  margin-bottom: 15px; }

.filtre .custom-control {
  font-weight: 500;
  margin-bottom: 15px; }

.filtre .form-control {
  border: none;
  border-bottom: 2px solid #E0E0E0;
  border-radius: 0; }

.form-control {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  background-clip: padding-box;
  background-color: #fff;
  color: #3E4651;
  padding: 0.375rem 0.75rem;
  border: 1px solid #E0E0E0;
  border-radius: 0.25rem;
  line-height: 1.5;
  width: 100%;
  height: calc(1.7em + .75rem + 2px);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control:focus, .form-control:hover {
    background-color: #fff;
    box-shadow: 0 0 0 0.2rem rgba(95, 168, 211, 0.4);
    color: #3E4651;
    border-color: #5FA8D3;
    outline: 0; }
  .form-control::placeholder {
    color: #9C9C9C;
    opacity: 1; }

.proje-banner-bg {
  position: relative;
  overflow: hidden;
  padding-bottom: 190px;
  margin-top: 1px; }

.proje-banner .breadcrumb {
  color: #fff;
  position: relative;
  z-index: 1; }
  .proje-banner .breadcrumb a {
    color: #fff; }

.proje-banner:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  background: #1B4965;
  width: 50%;
  height: 100%;
  clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%); }

.proje-banner .baslik {
  margin-top: 190px;
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 3rem;
  margin-bottom: 30px; }

.proje-banner .yazi {
  color: #fff;
  position: relative;
  z-index: 1;
  margin-bottom: 80px;
  display: block; }

.proje-banner .btn {
  position: relative;
  z-index: 1;
  background: none;
  border: 1px solid #fff;
  width: 220px; }

.proje-detay__baslik {
  display: block;
  margin-bottom: 60px; }

.proje-info {
  padding-right: 30px; }
  .proje-info__baslik {
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 30px; }
  .proje-info .yazi {
    line-height: 2; }
  .proje-info .proje-slider-arrows {
    justify-content: center;
    margin-top: 60px; }
  .proje-info .proje-arrow {
    margin: 7px; }

.proje-bilgiler {
  position: relative; }

.pbt {
  border-left: 2px solid rgba(27, 73, 101, 0.4);
  padding-left: 30px; }
  .pbt .btn {
    position: absolute;
    left: 30px;
    bottom: 0; }

.proje-bilgi {
  padding: 15px 0;
  border-bottom: 1px solid #E0E0E0; }
  .proje-bilgi__baslik {
    font-weight: 500; }
  .proje-bilgi__yazi {
    font-weight: 700;
    font-size: 1.2rem; }

.proje-detay-slider img {
  width: 100%; }

.proje-detay-paging {
  position: relative;
  top: -50px;
  padding: 0 15px; }
  .proje-detay-paging .as-arrows {
    position: relative;
    bottom: 0; }

.proje-detay-info {
  color: #fff;
  font-size: 2rem;
  font-weight: 600; }

.proje-harita {
  padding: 30px 0;
  background: #1B4965;
  position: relative; }
  .proje-harita__resim {
    margin-left: 15px;
    max-width: 90%; }
  .proje-harita__detaylar {
    background: #BEE9E8;
    padding: 90px 30px;
    padding-right: 10%;
    width: 40%;
    right: 0;
    z-index: 2; }

.iletisim {
  background: #1B4965;
  color: #fff;
  display: flex;
  justify-content: space-between; }
  .iletisim__baslik {
    width: 35%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 3rem;
    color: #BEE9E8; }
  .iletisim__form {
    width: 65%;
    background: #BEE9E8;
    padding: 90px;
    padding-right: 20%;
    color: #1B4965; }
  .iletisim .yazi {
    font-weight: 500;
    line-height: 1.8; }
  .iletisim .form-control {
    border-radius: 0;
    border: none;
    background: none;
    border-bottom: 1px solid #1B4965; }
    .iletisim .form-control::placeholder {
      color: #1B4965; }
  .iletisim .custom-control {
    font-weight: 500;
    font-size: .9rem; }
  .iletisim .custom-control-label::before {
    background: none;
    border-color: #1B4965; }

.h-yazi {
  line-height: 2;
  font-size: 1.1rem;
  font-weight: 500; }

.iletisim-card {
  padding: 30px;
  border: 1px solid #E0E0E0; }
  .iletisim-card .yazi {
    line-height: 1.8;
    font-weight: 500;
    font-size: .9rem; }
  .iletisim-card .baslik {
    margin-bottom: 15px; }

.iletisim-harita {
  width: 100%; }

.iletisim-icon {
  justify-content: flex-start; }
  .iletisim-icon img {
    margin-right: 15px; }
  .iletisim-icon strong {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 2; }
  .iletisim-icon small {
    display: block;
    font-size: 1rem;
    font-weight: 500; }

.iletisim-sayfa .container {
  padding-bottom: 60px;
  border-bottom: 1px solid #E0E0E0; }

@media (max-width: 1400px) {
  .slider-baslik {
    font-size: 3rem; }
  .slider-sag {
    padding-right: 15px !important; } }

@media (max-width: 1200px) {
  .anasayfa-slider .item {
    overflow: hidden; }
    .anasayfa-slider .item img {
      width: 110%;
      max-width: 110%;
      margin-left: -10%; }
  .slider-baslik {
    font-size: 2.4rem;
    width: 80%; }
  .menu__link {
    font-size: .9rem; }
  .logo {
    width: 200px; }
    .logo img {
      max-width: 80%; }
  .header__sag {
    width: calc(100% - 200px); }
  .footer-iletisim {
    font-size: 1.8rem; }
  .sol-menu {
    width: 250px; }
  .sayfa-sag {
    width: calc(100% - 280px); }
  .proje__baslik {
    font-size: 1.4rem; }
  .filtre__baslik {
    font-size: 1.2rem; }
  .proje-banner:before {
    width: 60%; }
  .proje-harita__detaylar {
    padding: 30px; } }

@media (max-width: 992px) {
  .anasayfa-slider .item {
    overflow: hidden; }
    .anasayfa-slider .item img {
      width: 150%;
      max-width: 150%;
      margin-left: -40%; }
  .anasayfa-slider .container {
    max-width: 100%; }
  .anasayfa-slider .slider-detay {
    top: auto;
    bottom: 30px;
    transform: none;
    left: 0;
    width: 100%; }
  .slider-alt {
    display: none; }
  .header .container, .slider-alt .container {
    max-width: 100%; }
  .menu {
    display: none; }
  .slider-baslik {
    font-size: 2rem; }
  .btn {
    font-size: .9rem; }
  .harita-baslik h1 {
    font-size: 2rem; }
  .harita-baslik strong {
    font-size: 1rem; }
  .slider-sag {
    padding-left: 33% !important;
    padding-right: 30px !important;
    padding-top: 30px; }
  .proje-slider-arrows {
    padding-right: 30px; }
  .footer {
    text-align: center; }
  .footer__col3 {
    padding-left: 0; }
  .sayfa-wrapper {
    flex-wrap: wrap; }
  .sol-menu {
    width: 100%;
    margin-bottom: 30px; }
  .sayfa-sag {
    width: 100%; }
  .proje-banner-bg .container {
    max-width: 100%; }
  .proje-banner {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
    .proje-banner:before {
      width: 100%;
      opacity: .8; }
  .header--ic .hamburger-inner, .header--ic .hamburger-inner::after, .header--ic .hamburger-inner::before {
    background-color: #1B4965; }
  .proje-bilgiler {
    padding-bottom: 90px; }
  .proje-harita__resim {
    max-width: calc(100% - 30px);
    margin: 0 auto;
    display: block; }
  .proje-harita__detaylar {
    position: relative;
    width: calc(100% - 60px);
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -30px;
    transform: none; }
  .iletisim__form {
    padding: 60px; } }

@media (max-width: 992px) {
  .btn-mobil {
    display: block;
    position: relative;
    top: 4px;
    padding: 0;
    outline: transparent; }
  .arka-active {
    display: block;
    opacity: 1; }
  .mobil-nav--icon {
    display: block;
    height: 25px; }
  .mobil {
    display: block;
    position: fixed;
    top: 0;
    left: -320px;
    background: #3E4651;
    color: #fff;
    width: 300px;
    height: 100%;
    max-width: calc(100% - 80px);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    z-index: 9999; }
    .mobil strong {
      font-weight: 500; }
    .mobil .nav-tabs {
      display: flex;
      position: absolute;
      bottom: 0;
      font-weight: 500;
      background: rgba(0, 0, 0, 0.2);
      width: 100%;
      z-index: 99999; }
      .mobil .nav-tabs li {
        text-align: center;
        width: 50%; }
    .mobil .tab {
      overflow: hidden; }
    .mobil .iletisim-bilgileri {
      padding: 30px;
      line-height: 1.7; }
      .mobil .iletisim-bilgileri .iletisim-box {
        font-size: 0.85rem;
        padding-left: 0; }
      .mobil .iletisim-bilgileri span {
        opacity: 0.7; }
      .mobil .iletisim-bilgileri a {
        color: #fff;
        text-decoration: underline; }
    .mobil .tablinks {
      display: block;
      font-size: 0.8rem;
      cursor: pointer;
      padding: 18px 0 15px;
      border-bottom: 3px solid transparent;
      opacity: 0.6;
      transition: all 0.3s; }
      .mobil .tablinks:hover {
        border-color: #BEE9E8;
        opacity: 0.8; }
      .mobil .tablinks.active {
        border-color: #BEE9E8;
        opacity: 1; }
    .mobil .tabcontent {
      padding-bottom: 45px;
      overflow-y: scroll;
      height: calc(100vh - 75px); }
  .mobil-logo {
    padding: 30px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
    .mobil-logo img {
      display: block;
      margin: 0 auto;
      width: 160px; }
    .mobil-logo .musteri-girisi {
      display: block;
      font-size: 14px;
      padding: 14px;
      margin: 0 auto;
      border-radius: 32px;
      width: 90%;
      text-decoration: none; }
      .mobil-logo .musteri-girisi i {
        right: 20px; }
  .mobil-baslik {
    display: block;
    font-size: 16px;
    font-weight: 500;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    padding: 25px 15px;
    border: 0;
    transition: all 0.3s; }
  .mobil-menu-li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .mobil-menu-li .btn-atik {
      display: block;
      padding: 15px 30px;
      margin: 0 auto !important;
      margin-top: 30px !important;
      width: auto;
      width: 230px !important; }
  .mobil-ana-menu .mobil-menu-li a {
    width: 100%; }
  .mobil-ana-menu .mobil-menu-li i {
    margin-top: 2px;
    float: right; }
  .mobil-menu-link {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    padding: 20px 20px 20px 35px;
    transition: all 0.3s;
    text-decoration: none !important; }
    .mobil-menu-link i {
      margin-right: 6px; }
    .mobil-menu-link:focus, .mobil-menu-link:hover {
      background: rgba(0, 0, 0, 0.1);
      color: #BEE9E8; }
    .mobil-menu-link.active {
      background: rgba(0, 0, 0, 0.1);
      color: #BEE9E8; }
  .mobil-alt-menu {
    display: none;
    background: rgba(0, 0, 0, 0.3);
    padding: 15px 0; }
    .mobil-alt-menu ul {
      padding: 10px 0;
      line-height: 1.4; }
    .mobil-alt-menu a {
      display: block;
      font-size: 15px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.8);
      padding: 12px 12px 12px 50px;
      text-decoration: none;
      transition: color 0.25s ease-in-out; }
      .mobil-alt-menu a:Hover {
        color: #BEE9E8; }
      .mobil-alt-menu a.active {
        color: #BEE9E8; }
  .mobil-active {
    left: 0; }
  .mobil-hr {
    background: rgba(255, 255, 255, 0.1);
    margin-left: -50px;
    border: 0;
    height: 1px; }
  .mobil-tab {
    overflow-y: scroll;
    height: calc(100vh - 80px); } }

@media (max-width: 768px) {
  .slider-baslik {
    width: 80%; }
  .logo {
    width: 150px; }
  .header__sag {
    width: calc(100% - 150px); }
  .header__ust {
    height: 30px;
    padding-left: 15px; }
  .header__alt {
    height: 60px;
    padding-left: 15px; }
  .anasayfa-slider .btn {
    width: 200px;
    padding: 8px 10px;
    font-size: .8rem;
    font-weight: 500; }
  .main-hakkimizda__baslik {
    font-size: 2.5rem; }
  .main-projeler .baslik {
    font-size: 2rem; }
  .proje-banner-bg {
    padding-bottom: 60px; }
  .proje-banner .baslik {
    margin-top: 60px;
    font-size: 1.4rem; }
  .proje-banner .yazi {
    font-size: .9rem;
    margin-bottom: 40px; }
  .header {
    height: auto; }
  .baslik {
    font-size: 1.4rem; }
    .baslik--kucuk {
      font-size: 1rem; }
  .yazi {
    font-size: .9rem; }
  .iletisim {
    flex-wrap: wrap; }
    .iletisim__baslik {
      width: 100%;
      padding: 30px 15px;
      font-size: 2rem; }
    .iletisim__form {
      width: 100%; } }

@media (max-width: 576px) {
  .anasayfa-slider .item img {
    width: 230%;
    max-width: 230%;
    margin-left: -100%;
    position: relative;
    bottom: -30%; }
  .slider-baslik {
    font-size: 1.4rem;
    width: 100%; }
  .header__telefon {
    display: none; }
  .logo {
    width: 100px; }
    .logo img {
      max-width: calc(100% - 15px); }
  .header__ust {
    justify-content: flex-end; }
  .header__sag {
    width: calc(100% - 100px); }
  .header-btn {
    font-size: .8rem; }
  .slider-bg {
    width: 100%; }
  .slider-sag {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .proje-slider-arrows {
    padding-right: 0;
    justify-content: center;
    margin-top: 30px; }
  .footer-alt {
    justify-content: center; }
  .iletisim__form {
    padding: 15px; }
  .iletisim-card {
    padding: 15px; }
  .iletisim-icon strong {
    font-size: 1.1rem; }
  .iletisim-icon small {
    font-size: .9rem; }
  .iletisim-icon img {
    width: 80px; } }

@media (max-width: 480px) {
  .header-btn {
    font-size: .7rem;
    padding: 4px 6px; }
    .header-btn img {
      display: none; } }
