@media (max-width:1400px) {
  .slider-baslik{
    font-size: 3rem;
  }

  .slider-sag{
    padding-right: 15px !important;
  }
}

@media (max-width:1200px) {

  .anasayfa-slider{
    .item{
     overflow: hidden;

     img{
       width: 110%;
       max-width: 110%;
       margin-left: -10%;
     }
   }
 }

  .slider-baslik{
    font-size: 2.4rem;
    width: 80%;
  }

  .menu__link{
    font-size: .9rem;
  }

  .logo{
    width: 200px;

    img{
      max-width: 80%;
    }
  }

  .header__sag{
    width: calc(100% - 200px);
  }

  .footer-iletisim{
    font-size: 1.8rem;
  }

  .sol-menu{
    width: 250px;
  }

  .sayfa-sag{
    width: calc(100% - 280px);
  }

  .proje__baslik{
    font-size: 1.4rem;
  }

  .filtre__baslik{
    font-size: 1.2rem;
  }

  .proje-banner:before{
    width: 60%;
  }

  .proje-harita__detaylar{
    padding: 30px;
  }
}
