@import 'reset', 'colors', 'mixin', 'global', 'hamburgers', 'bootstrap-grid', 'slick', 'fontawesome', 'forms';

.btn-mobil,
.mobil {
  display: none;
}

.btn-mobil {
  transform: scale(.7);
}

.arama-bg,
.mobil-bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: $renkss;
  width: 100%;
  height: 100%;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  z-index: 996;
}

.arka-active {
  display: block;
}

.main-slider {
  position: relative;
  margin-top: -150px;
}

.anasayfa-slider {
  position: relative;
  background: #fcfcfc;

  .item {
    position: relative;

    img {
      max-width: 100%;
    }
  }

  .slick-dots {
    bottom: 0;
    left: 40px;
    text-align: left;
    width: auto;
    z-index: 3;
  }

  .slick-dots li {
    margin: 0;
    width: auto;
  }

  .slick-dots li button {
    @extend .efekt;
    background: rgba(#fff, .2);
    padding: 0;
    width: 40px;
    height: 4px;

    &:before {
      content: '';
    }
  }

  .slick-active {
    button {
      background: #fff !important;
    }
  }

  .btn {
    background: $renk_acik;
    color: $renk_koyumavi;
    width: 250px;

    path {
      fill: $renk_koyumavi;
    }

    &:hover {
      color: #fff;

      path {
        fill: #fff;
      }
    }
  }
}

.slider-detay {
  position: absolute;
  top: 50%;
  left: 60px;
  width: calc(100% - 120px);
  transform: translateY(-50%);
  z-index: 2;
}

.slider-baslik {
  display: block;
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 60px;
  line-height: 1.2;
  width: 50%;
}

.slider-yazi {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 6px;
  line-height: 1.2;
}

.slider-btn {
  @extend .efekt;
  display: inline-block;
  position: relative;
  font-size: 1.2rem;
  font-weight: 600;
  color: $renk_koyumavi;
  border-bottom: 2px solid $renk_koyumavi;
  text-decoration: none;

  &:after {
    @extend .efekt;
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    background: $renk_mavi;
    margin: 0 auto;
    width: 0;
    height: 2px;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
}

.as-arrows {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 60px;
  right: 0;
  font-size: 20px;
  color: #fff;
  width: 80px;
  z-index: 3;

  a {
    @extend .efekt;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    background: #fff;
    color: $renk_yazi;
    cursor: pointer;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    text-decoration: none;

    &:hover {
      background: $renk_mavi;
      color: #fff;
      text-shadow: 1px 1px 22px #000;
    }
  }
}

.anasayfa-nav {
  position: absolute;
  bottom: 8px;
  left: 15px;

  span {
    display: inline-block;
    position: relative;
    font-size: 11px;
    font-weight: 600;
    color: #fff;
    width: 15px;
  }
}

.slider-alt {
  position: absolute;
  bottom: 0;
  left: 60px;
  width: calc(100% - 120px);
}

.slider-bar {
  position: absolute;
  bottom: 25px;
  width: 100%;
}

.slider-progress {
  position: relative;
  bottom: 6px;
  background: rgba(255, 255, 255, .6);
  overflow: hidden;
  width: 100%;
  height: 3px;
}

.slider-progress .progress {
  position: relative;
  background: #fff;
  width: 0;
  height: 3px;
}

.pagingInfo {
  position: absolute;
  bottom: 15px;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
}

.harita {
  outline: transparent !important;
  height: 800px;

  #map,
  canvas,
  iframe {
    outline: transparent !important;
  }
}

.header {
  position: relative;
  height: 150px;
  z-index: 2;

  &__sag {
    border-left: 1px solid #fff;
    width: calc(100% - 290px);
  }

  &__ust {
    @extend .flex;
    font-size: 0.9rem;
    font-weight: 500;
    padding-left: 30px;
    border-bottom: 1px solid #fff;
    height: 60px;

    a {
      color: #fff;
      text-decoration: none;
      @include efekt(color);

      &:hover {
        color: $renk_koyumavi;
      }
    }
  }

  &__telefon {}

  &__buttons {
    @extend .flex;
  }

  &__alt {
    @extend .flex;
    padding-left: 30px;
    height: 90px;
  }

  &--flex {
    @extend .flex;
    border-bottom: 1px solid #fff;
  }

  &--ic {
    .header--flex,
    .header__sag,
    .header__ust {
      border-color: $renk_cizgi;
    }

    .header__ust {
      a {
        color: $renk_koyumavi;

        &:hover {
          color: $renk_mavi;
        }
      }
    }

    .menu__link {
      color: $renk_koyumavi;

      &:hover {
        color: $renk_mavi;
      }
    }
  }
}

.logo {
  width: 290px;
}

.sosyal {
  margin-right: 30px;

  &__link {
    font-size: 1.1rem;
    margin-left: 15px;
  }
}

.tools {
  &__link {}
}

.btn-arama {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #fff;
}

.menu {
  &__link {
    display: inline-block;
    color: #fff;
    margin-right: 30px;
    line-height: 90px;
    text-decoration: none;
    @include efekt(color);
    font-weight: 600;

    &:hover {
      color: $renk_koyumavi;
    }
  }
}

.header-btn {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background: $renk_acik;
  color: $renk_koyumavi;
  padding: 10px 15px;
  border-radius: 32px;

  &:hover {
    background: #fff;
  }
}

.harita-baslik {
  position: absolute;
  top: 10%;
  left: 5%;
  color: $renk_koyumavi;
  z-index: 3;

  h1 {
    display: block;
    font-size: 4rem;
    font-weight: 700;
  }

  strong {
    font-size: 2rem;
    font-weight: 700;
  }
}

.main-hakkimizda {
  padding: 60px 0;

  .container {
    background: #f4f4f4;
    padding: 15px;
  }

  .row {
    align-items: center;
  }

  &__baslik {
    display: block;
    font-family: $roboto;
    font-size: 4rem;
    margin-bottom: 30px;
    line-height: 1.2;
  }

  &__yazi {
    font-size: 1.1rem;
    font-weight: 500;
    padding-left: 30px;
  }
}

.main-projeler {
  position: relative;
  background: $renk_koyumavi;
  padding: 120px 0;

  .row {
    align-items: center;
  }

  .btn--icon {
    background: $renk_koyumavi;
    color: #fff;
    width: 220px;
  }

  .projeler-slider {
    margin-bottom: 0 !important;
    z-index: 2;
  }

  .baslik {
    font-family: $roboto;
    font-size: 3rem;
  }

  .yazi {
    font-weight: 500;
    line-height: 1.8;
  }
}

.slider-bg {
  @extend .dikeyAbsolute;
  right: 0;
  background: $renk_acik;
  width: 70%;
  height: calc(100% - 120px);
  z-index: 1;
}

.slider-sag {
  position: relative;
  color: $renk_koyumavi;
  padding-left: 60px !important;
  padding-right: 20% !important;

  .slider-ic {
    position: relative;
    z-index: 2;
  }
}

.footer {
  padding-top: 30px;
  padding-bottom: 10px;

  &__col1 {}

  &-menu__link {}

  &__col2 {}

  &__col3 {
    padding-left: 30px;
  }
}

.footer-menu {
  &__link {
    display: inline-block;
    font-weight: 600;
    color: $renk_koyumavi;
    text-decoration: none;
    @include efekt(color);
    margin-bottom: 10px;

    &:hover {
      color: $renk_mavi;
    }
  }

  &--buyuk {
    font-size: 1.8rem;
  }
}

.footer-adres {
  strong {
    display: block;
    font-weight: 700;
    margin-bottom: 10px;
  }

  span {
    font-size: 0.9rem;
    font-weight: 500;
  }
}

.footer-iletisim {
  font-size: 2rem;
  font-weight: 600;

  a {
    font-size: 1.5rem;
    color: $renk_koyumavi;
    text-decoration: none;
    @include efekt(color);

    &:hover {
      color: $renk_mavi;
    }
  }
}

.footer-alt {
  @extend .flex;
  font-size: 0.9rem;
  font-weight: 500;

  &__sag {
    @extend .flex;

    a {
      display: inline-block;
      color: $renk_koyumavi;
      text-decoration: none;
    }

    .tools__link {
      padding-right: 15px;
      margin: 15px;
      border-right: 1px solid $renk_koyumavi;
    }
  }
}

.proje-slider-nav {
  position: relative;
  z-index: 3;
}

.proje-slider-arrows {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.proje-arrow {
  opacity: 0.7;
  @include efekt(opacity);

  &:hover {
    opacity: 1;
  }

  svg {
    vertical-align: middle;
  }
}

.prevAS2 {
  margin-right: 15px;
}

.breadcrumb {
  font-size: 0.9rem;
  font-weight: 500;
  color: #777;

  &__item {
    display: inline-block;
  }

  &__link {
    color: #777;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__ayrac {
    display: inline-block;
    margin: 0 5px;
  }
}

.proje {
  @include efekt(background);
  padding: 15px;

  &:hover {
    background: $renk_koyumavi;

    .proje__baslik,
    .proje__kategori {
      color: #fff;
    }
  }

  a {
    text-decoration: none;
  }

  &__resim {
    display: block;
    margin-bottom: 15px;
    width: 100%;
  }

  &__baslik {
    display: block;
    font-size: 2rem;
    font-weight: 600;
    color: $renk_koyumavi;
    margin-bottom: 4px;
    @include efekt(color);
  }

  &__kategori {
    font-size: 0.9rem;
    color: #828282;
    @include efekt(color);
  }
}

.sayfa-wrapper {
  @extend .flex;
  align-items: flex-start;
}

.sol-menu {
  width: 300px;
}

.sayfa-sag {
  width: calc(100% - 360px);
}

hr {
  background: $renk_cizgi;
  border: none;
  height: 1px;
}

.filtre {
  &__baslik {
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
    color: $renk_mavi;
    margin-bottom: 15px;
  }

  &__formlar {}

  .custom-control {
    font-weight: 500;
    margin-bottom: 15px;
  }

  .form-control {
    border: none;
    border-bottom: 2px solid $renk_cizgi;
    border-radius: 0;
  }
}

.form-control {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  background-clip: padding-box;
  background-color: #fff;
  color: $renk_yazi;
  padding: 0.375rem 0.75rem;
  border: 1px solid $renk_cizgi;
  border-radius: 0.25rem;
  line-height: 1.5;
  width: 100%;
  height: calc(1.7em + .75rem + 2px);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus,
  &:hover {
    background-color: #fff;
    box-shadow: 0 0 0 0.2rem rgba($renk_mavi, .4);
    color: $renk_yazi;
    border-color: $renk_mavi;
    outline: 0;
  }

  &::placeholder {
    color: #9C9C9C;
    opacity: 1;
  }
}

.proje-banner-bg {
  position: relative;
  overflow: hidden;
  padding-bottom: 190px;
  margin-top: 1px;
}

.proje-banner {
  .breadcrumb {
    color: #fff;
    position: relative;
    z-index: 1;

    a {
      color: #fff;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background: $renk_koyumavi;
    width: 50%;
    height: 100%;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  }

  .baslik {
    margin-top: 190px;
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 3rem;
    margin-bottom: 30px;
  }

  .yazi {
    color: #fff;
    position: relative;
    z-index: 1;
    margin-bottom: 80px;
    display: block;
  }

  .btn {
    position: relative;
    z-index: 1;
    background: none;
    border: 1px solid #fff;
    width: 220px;
  }
}

.proje-detay-wrapper {}

.proje-detay {
  &__baslik {
    display: block;
    margin-bottom: 60px;
  }
}

.proje-info {
  padding-right: 30px;

  &__baslik {
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .yazi {
    line-height: 2;
  }

  .proje-slider-arrows {
    justify-content: center;
    margin-top: 60px;
  }

  .proje-arrow {
    margin: 7px;
  }
}

.proje-info-slider {}

.proje-bilgiler {
  position: relative;
}

.pbt {
  border-left: 2px solid rgba($renk_koyumavi, .4);
  padding-left: 30px;

  .btn {
    position: absolute;
    left: 30px;
    bottom: 0;
  }
}

.proje-bilgi {
  @extend .flex;
  padding: 15px 0;
  border-bottom: 1px solid $renk_cizgi;

  &__baslik {
    font-weight: 500;
  }

  &__yazi {
    font-weight: 700;
    font-size: 1.2rem;
  }
}

.proje-detay-slider {
  img{
    width: 100%;
  }
}

.proje-detay-paging {
  position: relative;
  top: -50px;
  padding: 0 15px;
  @extend .flex;

  .as-arrows {
    position: relative;
    bottom: 0;
  }
}

.proje-detay-info {
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
}

.proje-harita {
  padding: 30px 0;
  background: $renk_koyumavi;
  position: relative;

	&__resim {
    margin-left: 15px;
    max-width: 90%;
	}

	&__detaylar {
    background: $renk_acik;
    padding: 90px 30px;
    padding-right: 10%;
    width: 40%;
    right: 0;
    @extend .dikeyAbsolute;
    z-index: 2;
    .baslik {
    }

    .yazi {

    }
	}

}


.iletisim {
  background: $renk_koyumavi;
  color: #fff;
  display: flex;
  justify-content: space-between;

	&__baslik {
    width: 35%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 3rem;
    color: $renk_acik;
	}

	&__form {
    width: 65%;
    background: $renk_acik;
    padding: 90px;
    padding-right: 20%;
    color: $renk_koyumavi;
	}

  .yazi{
    font-weight: 500;
    line-height: 1.8;
  }

	&__icBaslik {

	}

  .form-control {
    border-radius: 0;
    border: none;
    background: none;
    border-bottom: 1px solid $renk_koyumavi;

    &::placeholder{
      color: $renk_koyumavi;
    }
  }

  .custom-control{
    font-weight: 500;
    font-size: .9rem;
  }

  .custom-control-label::before{
    background: none;
    border-color: $renk_koyumavi;
  }

}

.h-yazi{
  line-height: 2;
  font-size: 1.1rem;
  font-weight: 500;
}

.iletisim-card{
  padding: 30px;
  border: 1px solid $renk_cizgi;
  @extend .bdrs;

  .yazi{
    line-height: 1.8;
    font-weight: 500;
    font-size: .9rem;
  }

  .baslik{
    margin-bottom: 15px;
  }
}

.iletisim-harita{
  @extend .bdrs;
  width: 100%;
}

.iletisim-icon{
  @extend .flex;
  justify-content: flex-start;

  img{
    margin-right: 15px;
  }

  strong{
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 2;
  }

  small{
    display: block;
    font-size: 1rem;
    font-weight: 500;
  }
}

.iletisim-sayfa{
  .container{
    padding-bottom: 60px;
    border-bottom: 1px solid $renk_cizgi;
  }
}
@import 'style1200', 'style992', 'mobil-nav', 'style768', 'style576';
