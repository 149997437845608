@media (max-width: 992px) {
  .anasayfa-slider{
    .item{
     overflow: hidden;

     img{
       width: 150%;
       max-width: 150%;
       margin-left: -40%;
     }
   }

   .container{
     max-width: 100%;
   }

   .slider-detay{
     top: auto;
     bottom: 30px;
     transform: none;
     left: 0;
     width: 100%;
   }
  }

  .slider-alt{
    display: none;
  }

  .header, .slider-alt{
    .container{
      max-width: 100%;
    }
  }

  .menu{
    display: none;
  }

  .slider-baslik{
    font-size: 2rem;
  }
  .btn{
    font-size: .9rem;
  }

  .harita-baslik{
    h1{
      font-size: 2rem;
    }

    strong{
      font-size: 1rem;
    }
  }

  .slider-sag{
    padding-left: 33% !important;
    padding-right: 30px !important;
    padding-top: 30px;
  }

  .proje-slider-arrows{
    padding-right: 30px;
  }

  .footer{
    text-align: center;
  }

  .footer__col3{
    padding-left: 0;
  }

  .sayfa-wrapper{
    flex-wrap: wrap;
  }

  .sol-menu{
    width: 100%;
    margin-bottom: 30px;
  }

  .sayfa-sag{
    width: 100%;
  }

  .proje-banner-bg{
    .container{
      max-width: 100%;
    }
  }

  .proje-banner{
    text-shadow: 1px 1px 1px $renkss-4;
    &:before{
      width: 100%;
      opacity: .8;
    }
  }

  .header--ic{
    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before{
      background-color: $renk_koyumavi;
    }
  }

  .proje-bilgiler{
    padding-bottom: 90px;
  }

  .proje-harita__resim{
    max-width: calc(100% - 30px);
    margin: 0 auto;
    display: block;
  }

  .proje-harita__detaylar{
    position: relative;
    width: calc(100% - 60px);
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -30px;
    transform: none;
  }

  .iletisim__form{
    padding: 60px;
  }
}
