@media (max-width: 768px) {
  .slider-baslik{
    width: 80%;
  }

  .logo{
    width: 150px;
  }

  .header__sag{
    width: calc(100% - 150px);
  }

  .header__ust{
    height: 30px;
    padding-left: 15px;
  }

  .header__alt{
    height: 60px;
    padding-left: 15px;
  }

  .anasayfa-slider .btn{
    width: 200px;
    padding: 8px 10px;
    font-size: .8rem;
    font-weight: 500;
  }

  .main-hakkimizda__baslik{
    font-size: 2.5rem;
  }

  .main-projeler .baslik{
    font-size: 2rem;
  }
  .proje-banner-bg{
    padding-bottom: 60px;
  }

  .proje-banner .baslik{
    margin-top: 60px;
    font-size: 1.4rem;
  }

  .proje-banner .yazi{
    font-size: .9rem;
    margin-bottom: 40px;
  }

  .header{
    height: auto;
  }

  .baslik{
    font-size: 1.4rem;

    &--kucuk{
      font-size: 1rem;
    }
  }

  .yazi{
    font-size: .9rem;
  }

  .iletisim{
    flex-wrap: wrap;

    &__baslik{
      width: 100%;
      padding: 30px 15px;
      font-size: 2rem;
    }

    &__form{
      width: 100%;
    }
  }
}
