.fa,
.fab,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-map-marked-alt:before {
  content: "\f5a0";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-times:before {
  content: "\f00d";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-heart:before {
  content: "\f004";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-phone:before {
  content: "\f095";
}

.fa-map:before {
  content: "\f279";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-search:before {
  content: "\f002";
}

.fa-user:before {
  content: "\f007";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-th:before {
  content: "\f00a";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-grip-horizontal:before {
  content: "\f58d";
}

.fa-comment:before {
  content: "\f075";
}

.fa-edit:before {
  content: "\f044";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-minus:before {
  content: "\f068";
}

.fa-plus:before {
  content: "\f067";
}

.fa-clock:before {
  content: "\f017";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-up:before {
  content: "\f0d8";
}
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../css/webfonts/fa-brands-400.eot");
  src: url("../css/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../css/webfonts/fa-brands-400.woff2") format("woff2"), url("../css/webfonts/fa-brands-400.woff") format("woff"), url("../css/webfonts/fa-brands-400.ttf") format("truetype"), url("../css/webfonts/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../css/webfonts/fa-regular-400.eot");
  src: url("../css/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../css/webfonts/fa-regular-400.woff2") format("woff2"), url("../css/webfonts/fa-regular-400.woff") format("woff"), url("../css/webfonts/fa-regular-400.ttf") format("truetype"), url("../css/webfonts/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../css/webfonts/fa-solid-900.eot");
  src: url("../css/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../css/webfonts/fa-solid-900.woff2") format("woff2"), url("../css/webfonts/fa-solid-900.woff") format("woff"), url("../css/webfonts/fa-solid-900.ttf") format("truetype"), url("../css/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
