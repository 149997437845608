@media (max-width: 992px) {
  .btn-mobil {
    display: block;
    position: relative;
    top: 4px;
    padding: 0;
    outline: transparent;
  }

  .arka-active {
    display: block;
    opacity: 1;
  }

  .mobil-nav--icon {
    display: block;
    height: 25px;
  }

  .mobil {
    display: block;
    position: fixed;
    top: 0;
    left: -320px;
    background: $renk_yazi;
    color: #fff;
    width: 300px;
    height: 100%;
    max-width: calc(100% - 80px);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    z-index: 9999;

    strong {
      font-weight: 500;
    }

    .nav-tabs {
      display: flex;
      position: absolute;
      bottom: 0;
      font-weight: 500;
      background: $renkss-2;
      width: 100%;
      z-index: 99999;

      li {
        text-align: center;
        width: 50%;
      }
    }

    .tab {
      overflow: hidden;
    }

    .iletisim-bilgileri {
      padding: 30px;
      line-height: 1.7;

      .iletisim-box {
        font-size: 0.85rem;
        padding-left: 0;
      }

      span {
        opacity: 0.7;
      }

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    .tablinks {
      display: block;
      font-size: 0.8rem;
      cursor: pointer;
      padding: 18px 0 15px;
      border-bottom: 3px solid transparent;
      opacity: 0.6;
      transition: all 0.3s;

      &:hover {
        border-color: $renk_acik;
        opacity: 0.8;
      }

      &.active {
        border-color: $renk_acik;
        opacity: 1;
      }
    }

    .tabcontent {
      padding-bottom: 45px;
      overflow-y: scroll;
      height: calc(100vh - 75px);
    }
  }

  .mobil-logo {
    padding: 30px 0;
    border-bottom: 1px solid $renksb;

    img {
      display: block;
      margin: 0 auto;
      width: 160px;
    }

    .musteri-girisi {
      display: block;
      font-size: 14px;
      padding: 14px;
      margin: 0 auto;
      border-radius: 32px;
      width: 90%;
      text-decoration: none;

      i {
        right: 20px;
      }
    }
  }

  .mobil-baslik {
    display: block;
    font-size: 16px;
    font-weight: 500;
    background: $renkss-2;
    color: #fff;
    padding: 25px 15px;
    border: 0;
    transition: all 0.3s;
  }

  .mobil-menu-li {
    border-bottom: 1px solid $renkss;

    .btn-atik {
      display: block;
      padding: 15px 30px;
      margin: 0 auto !important;
      margin-top: 30px !important;
      width: auto;
      width: 230px !important;
    }
  }

  .mobil-ana-menu {
    .mobil-menu-li {
      a {
        width: 100%;
      }

      i {
        margin-top: 2px;
        float: right;
      }
    }
  }

  .mobil-menu-link {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    padding: 20px 20px 20px 35px;
    transition: all 0.3s;
    text-decoration: none !important;

    i {
      margin-right: 6px;
    }

    &:focus,
    &:hover {
      background: $renkss;
      color: $renk_acik;
    }

    &.active {
      background: $renkss;
      color: $renk_acik;
    }
  }

  .mobil-alt-menu {
    display: none;
    background: $renkss-3;
    padding: 15px 0;

    ul {
      padding: 10px 0;
      line-height: 1.4;
    }

    a {
      display: block;
      font-size: 15px;
      font-weight: 500;
      color: rgba(#fff, .8);
      padding: 12px 12px 12px 50px;
      text-decoration: none;
      @include efekt(color);

      &:Hover {
        color: $renk_acik;
      }

      &.active {
        color: $renk_acik;
      }
    }
  }

  .mobil-active {
    left: 0;
  }

  .mobil-hr {
    background: $renksb;
    margin-left: -50px;
    border: 0;
    height: 1px;
  }

  .mobil-tab {
    overflow-y: scroll;
    height: calc(100vh - 80px);
  }
}
