@media (max-width: 576px) {
  .anasayfa-slider{
     .item{
       img{
         width: 230%;
         max-width: 230%;
         margin-left: -100%;
         position: relative;
         bottom: -30%;
       }
     }
   }

   .slider-baslik{
     font-size: 1.4rem;
     width: 100%;
   }

   .header__telefon{
     display: none;
   }

   .logo{
     width: 100px;

     img{
       max-width: calc(100% - 15px);
     }
   }

   .header__ust{
     justify-content: flex-end;
   }

   .header__sag{
     width: calc(100% - 100px);
   }

   .header-btn{
     font-size: .8rem;
   }

   .slider-bg{
     width: 100%;
   }

   .slider-sag{
     padding-left: 15px !important;
     padding-right: 15px !important;
   }

   .proje-slider-arrows{
     padding-right: 0;
     justify-content: center;
     margin-top: 30px;
   }

   .footer-alt{
     justify-content: center;
   }

   .iletisim__form{
     padding: 15px;
   }

   .iletisim-card{
     padding: 15px;
   }

   .iletisim-icon{
     strong{
       font-size: 1.1rem;
     }

     small{
       font-size: .9rem;
     }
     img{
       width: 80px;
     }
   }
}

@media(max-width:480px){
  .header-btn{
    font-size: .7rem;
    padding: 4px 6px;

    img{
      display: none;
    }
  }
}
