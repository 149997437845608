$mont: 'Montserrat', sans-serif;
$roboto: 'Roboto Slab', serif;

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  font-family: $mont;
  line-height: 1.5;
  background-attachment: fixed;
  color: $renk_koyumavi;
}

img{
  max-width: 100%;
}

button,
input,
select,
textarea {
  font-family: $mont;
}

.pos-rel{
  position: relative;
}

.slick-slide{
  outline: transparent !important;
}

.noscroll {
  overflow: hidden;
}

.tab-panel {
  display: none;
}

.tab-active {
  display: block;
  animation: fadeIn 0.5s;
}

.tabcontent {
  animation: fadeIn 0.5s;
}

.fadeIn {
  animation: fadeIn 0.5s;
}

.btn{
  background: $renk_yazi;
  color: #fff;
  padding: 8px 20px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  @extend .efekt;

  &:Hover{
    background: $renk_acik;
    @extend .bxs;
  }

  &--turuncu{
    background: none;
    border: 2px solid $renk_acik;
    color: $renk_acik;

    &:hover{
      color: #fff;
      @extend .bxs;
    }
  }

  &--outline{
    background: none;
    border: 1px solid $renk_koyumavi;
    color: $renk_koyumavi;

    &:hover{
      background: $renk_mavi;
      color: #fff;
      border-color: $renk_mavi;
    }
  }

  &--icon{
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg{
      margin-left: 6px;

      path{
        fill:#fff;
      }
    }

    &:Hover{
      background: $renk_mavi !important;
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.baslik{
  font-size: 2.2rem;
  font-weight: 700;
  display: block;

  &--kucuk{
    font-size: 1.6rem;
  }

  &--buyuk{
    font-size: 2.8rem;
  }
}
